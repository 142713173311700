import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export default create(
  subscribeWithSelector((set) => {
    return {
      blockCount: 20,
      blockSeed: 0,

      // Time

      startTime: 0,
      endTime: 0,

      // PHASES
      phase: "ready",

      start: (e) => {
        set(({ phase }) => {
          if (phase === "ready") {
            return { phase: "playing", startTime: Date.now() };
          }
          return {};
        });
      },
      restart: () => {
        set(({ phase }) => {
          if (["playing", "ended"].includes(phase))
            return { phase: "ready", blockSeed: Math.random() };
          return {};
        });
      },
      end: () => {
        set(({ phase }) => {
          if (phase === "playing")
            return { phase: "ended", endTime: Date.now() };
          return {};
        });
      },
    };
  })
);
