import React, { useRef, useEffect } from 'react';
import { addEffect } from '@react-three/fiber'
import { useKeyboardControls } from '@react-three/drei';
import useGame from './Store/useGame';



const Interface = () => {
    const { forward, backward, left, right, jump  } = useKeyboardControls((state) => state);
    const restart = useGame((state) => state.restart);
    const phase = useGame((state) => state.phase);
    const time = useRef();

    useEffect(() => {
       const unscribeEffect = addEffect(() => {
           const { phase, endTime, startTime} = useGame.getState();
            let elapsedTime = 0;
            if(phase === 'playing') {
                elapsedTime = Date.now() - startTime;
            } else if(phase === 'ended') {
                elapsedTime = endTime - startTime;
                elapsedTime /= 1000;
                elapsedTime = elapsedTime.toFixed(2);
               if(time.current) time.current.textContent = elapsedTime;
            }
        });

        return () => {
            unscribeEffect();
        }
    },[]);

  return (
    <div className='interface'>
        <div className='time' ref={time}>0.00</div>
        {
            phase === 'ended' && <div className="restart" onClick={() => restart()}>Restart</div>
        }
         {/* Controls */}
    <div className="controls">
        <div className="raw">
            <div className={`key ${forward ? 'active': ''}`}></div>
        </div>
        <div className="raw">
            <div className={`key ${left ? 'active': ''}`}></div>
            <div className={`key ${backward ? 'active': ''}`}></div>
            <div className={`key ${right ? 'active': ''}`}></div>
        </div>
        <div className="raw">
            <div className={`key ${jump ? "active" : ""} large`}></div>
        </div>
    </div>

    </div>
  )
}

Interface.propTypes = {}

export default Interface