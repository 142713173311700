import { OrbitControls } from "@react-three/drei";
import { Physics, Debug } from "@react-three/rapier";
import { shallow } from 'zustand/shallow'

import Lights from "./Lights.jsx";
import Level, { BlockEnd } from "./Level";
import Player from './Player'


import useGame from './Store/useGame'
import Effects from "./Effect";

export default function Experience() {
  const { blockCount, blockSeed } = useGame((state) => ({ blockCount: state.blockCount, blockSeed: state.blockSeed}), shallow);

  return (
    <>
     <color args={["#252731"]} attach="background"/>
      {/* <OrbitControls makeDefault /> */}

      <Physics >
        {/* <Debug/> */}
        <Lights />

        <Level count={blockCount} seed={blockSeed}/>
        <Player/>
        
      </Physics>
      <Effects/>
    </>
  );
}
